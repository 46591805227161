@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url("./fonts/Ubuntu-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url("./fonts/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url("./fonts/Ubuntu-Light.ttf") format("truetype");
  font-style: Light;
}

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url("./fonts/Ubuntu-Medium.ttf") format("truetype");
  font-style: Medium;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
div,
button {
  font-family: "Ubuntu";
}
